import { onKeyStroke } from "@vueuse/core";
import { type Ref, ref } from "vue";
import {
  findIndexByKey,
  findNextOptionByKey,
  getValueByKey,
} from "./listKeyValue";

export const useTypeAhead = (
  targetElement: Ref<HTMLElement | undefined>,
  list: Ref<Record<string, unknown>[]>,
  listKey: string,
  listValue: string,
  active: Ref<boolean | undefined>,
  activeIndex: Ref<number | undefined>,
  focusByOptionIndex?: (index: number) => void,
) => {
  const typeAheadClear = ref<ReturnType<typeof setTimeout>>();
  const typeAhead = ref<string>("");

  const TIMEOUT_VALUE_MILIS = 800;

  onKeyStroke(
    true,
    (e) => {
      if (
        e.key &&
        e.key.length === 1 &&
        !e.ctrlKey &&
        !e.shiftKey &&
        !e.metaKey &&
        active.value
      ) {
        clearTimeout(typeAheadClear.value);
        typeAhead.value += e.key;
        const matchingOption = findNextOptionByKey(
          list.value,
          listValue,
          typeAhead.value,
          activeIndex.value,
        );
        if (matchingOption) {
          const matchingOptionIndex = findIndexByKey(
            list.value,
            listKey,
            getValueByKey(matchingOption, listKey),
          );
          if (focusByOptionIndex) {
            focusByOptionIndex(matchingOptionIndex);
          }
        }
        typeAheadClear.value = setTimeout(
          () => (typeAhead.value = ""),
          TIMEOUT_VALUE_MILIS,
        );
      }
    },
    { target: targetElement },
  );
};
