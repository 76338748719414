import { findStartsWith } from "./stringUtils";

export function getValueByKey(
  option: Record<string, unknown>,
  key: string,
): string | number {
  if (key in option) {
    const value = option[key];
    if (
      value !== undefined &&
      (typeof value === "string" || typeof value === "number")
    ) {
      return value;
    }
  }
  throw Error("List option does not contain key: " + key);
}

export function findIndexByKey(
  list: Record<string, unknown>[],
  listKey: string,
  searchedKey: string | number,
) {
  return list.findIndex((element) => {
    if (element[listKey] === searchedKey) {
      return true;
    }
  });
}

export function findValueByKey(
  list: Record<string, unknown>[],
  listKey: string,
  listValue: string,
  searchedKey: string | number | undefined,
) {
  const foundOption = list.find((element) => {
    if (element[listKey] === searchedKey) {
      return true;
    } else if (
      typeof element[listKey] != typeof searchedKey &&
      String(element[listKey]) === String(searchedKey)
    ) {
      return true;
    }
  });
  if (foundOption) {
    return getValueByKey(foundOption, listValue);
  }
}

export function findNextOptionByKey(
  list: Record<string, unknown>[],
  listValue: string,
  searchText: string,
  startIndex: number | undefined,
) {
  let searchList = list;
  if (startIndex !== undefined) {
    searchList = list.slice(startIndex + 1).concat(list.slice(0, startIndex));
  }
  return findStartsWith(searchText, searchList, [listValue], true);
}
