import { onKeyStroke } from "@vueuse/core";
import { ref, type Ref } from "vue";

type ValueT = { id: string; option: Record<string, unknown> } | undefined;

type EmitT<T> = {
  (e: T, value?: ValueT): void;
};

type EmitSpecificationT<T> = { emit: EmitT<T>; eventName: T };

export const useListFocusState = <T>(
  id: string,
  targetElement: Ref<HTMLElement | undefined>,
  list: Ref<Record<string, unknown>[]>,
  listKey: string,
  useFocusIndication: boolean,
  emitSpecification?: EmitSpecificationT<T>,
) => {
  const focusedIndex = ref<number>();
  const focusByMouse = ref<boolean>(true);

  onKeyStroke(
    true,
    (e) => {
      if (e.key && !e.ctrlKey && !e.shiftKey && !e.metaKey) {
        focusByMouse.value = false;
      }
    },
    { target: targetElement },
  );

  const optionRefs = ref<Record<string, HTMLElement>>({});

  function emit(value: ValueT) {
    if (emitSpecification) {
      emitSpecification.emit(emitSpecification.eventName, value);
    }
  }

  function setOptionRef(element: HTMLElement, key: string | number) {
    if (element) {
      optionRefs.value[key] = element;
    }
  }

  function optionId(index: number) {
    return id + "_option_" + index;
  }

  function focusByOptionIndex(index: number) {
    if (index < 0) {
      focusedIndex.value = undefined;
      return;
    }
    focusedIndex.value = index;
    const focusedKey = list.value[index][listKey];
    if (
      !useFocusIndication &&
      (typeof focusedKey === "string" || typeof focusedKey === "number")
    ) {
      optionRefs.value[focusedKey].focus({ preventScroll: true });
      if (!focusByMouse.value) {
        optionRefs.value[focusedKey].scrollIntoView({
          block: "center",
          inline: "center",
        });
        focusByMouse.value = true;
      }
    } else if (
      useFocusIndication &&
      (typeof focusedKey === "string" || typeof focusedKey === "number")
    ) {
      if (!focusByMouse.value) {
        optionRefs.value[focusedKey].scrollIntoView({
          block: "center",
          inline: "center",
        });
        focusByMouse.value = true;
      }
      emit({
        id: optionId(index),
        option: list.value[index],
      });
    }
  }

  function focusNextOption() {
    if (
      focusedIndex.value !== undefined &&
      focusedIndex.value < list.value.length - 1
    ) {
      focusByOptionIndex(++focusedIndex.value);
    } else if (useFocusIndication) {
      focusedIndex.value = undefined;
      emit(undefined);
    }
  }

  function focusPreviousOption() {
    if (focusedIndex.value !== undefined && focusedIndex.value > 0) {
      focusByOptionIndex(--focusedIndex.value);
    } else if (useFocusIndication) {
      focusedIndex.value = undefined;
      emit(undefined);
    }
  }

  function focusFirstOption() {
    focusByOptionIndex(0);
  }

  function resetFocus() {
    if (!useFocusIndication) {
      focusedIndex.value = undefined;
    }
  }

  function focusLastOption() {
    focusByOptionIndex(list.value.length - 1);
  }

  function setFocusOnOption(selectionState?: Record<string | number, boolean>) {
    if (list.value[0] && listKey in list.value[0]) {
      if (selectionState) {
        list.value.some((element, index) => {
          const elementKey = element[listKey];
          if (
            (typeof elementKey === "string" ||
              typeof elementKey === "number") &&
            selectionState[elementKey]
          ) {
            focusedIndex.value = index;
            return true;
          }
        });
      }
      if (!focusedIndex.value) {
        focusedIndex.value = 0;
      }
      focusByOptionIndex(focusedIndex.value);
    }
  }

  return {
    focusedIndex,
    setOptionRef,
    optionId,
    setFocusOnOption,
    resetFocus,
    focusByOptionIndex,
    focusNextOption,
    focusPreviousOption,
    focusLastOption,
    focusFirstOption,
  };
};
