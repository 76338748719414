<template>
  <ul
    :id="id"
    ref="listboxContainer"
    role="listbox"
    :aria-multiselectable="undefinedIfNotTrue(multipleRef)"
    class="group overflow-auto rounded p-1 data-[use-focus-indication='true']:focus-within:outline data-[use-focus-indication='true']:focus-within:outline-2 data-[use-focus-indication='true']:focus-within:outline-primary-1 data-[use-focus-indication='true']:focus-within:data-disabled:outline-neutral-4"
    :tabindex="getTabindex()"
    :aria-disabled="isDisabled"
    :data-use-focus-indication="undefinedIfNotTrue(useFocusIndication)"
    :data-disabled="undefinedIfNotTrue(isDisabled)"
    @focus="setFocusOnOption(selectionState)"
  >
    <li
      v-for="(option, index) in list"
      :id="optionId(index)"
      :key="getValueByKey(option, listKey)"
      :ref="
        (element) =>
          setOptionRef(element as HTMLElement, getValueByKey(option, listKey))
      "
      role="option"
      :aria-selected="
        !multipleRef
          ? selectionState[getValueByKey(option, listKey)]
          : undefined
      "
      :aria-checked="
        multipleRef ? selectionState[getValueByKey(option, listKey)] : undefined
      "
      :data-selected="
        showSelection && selectionState[getValueByKey(option, listKey)]
      "
      class="group/element block w-full cursor-pointer rounded first:mt-0 last:mb-0 hover:bg-neutral-8 contrast:hover:bg-c-primary-0 contrast:hover:text-base-2 focus-visible:outline focus-visible:outline-2 focus-visible:outline-primary-1 data-selected:bg-secondary-9 hover:data-selected:bg-secondary-8 contrast:data-selected:bg-base-1 contrast:hover:data-selected:bg-c-primary-0 contrast:hover:data-selected:outline-c-primary-0 contrast:data-selected:text-base-2 data-[focus-indication='true']:outline data-[focus-indication='true']:outline-2 data-[focus-indication='true']:outline-primary-1 focus-visible:group-data-disabled:outline-neutral-4 data-[focus-indication='true']:group-data-disabled:outline-neutral-4 -outline-offset-1"
      :tabindex="focusedIndex === index && focused ? '0' : '-1'"
      :data-focus-indication="focusedIndex === index ? 'true' : undefined"
      @click="toggleOption(option, focusByOptionIndex)"
      @keydown.space.prevent="toggleOption(option, focusByOptionIndex)"
      @mousedown.prevent=""
      @keydown.down.prevent="focusNextOption"
      @keydown.up.prevent="focusPreviousOption"
      @keydown.home.prevent="focusFirstOption"
      @keydown.end.prevent="focusLastOption"
      @keydown.ctrl.a.prevent.stop="toggleAllOptions"
    >
      <slot
        :key="getValueByKey(option, listKey)"
        :value="getValueByKey(option, listValue)"
        :option="option"
        :toggle="() => toggleOption(option, focusByOptionIndex)"
        :selected="selectionState[getValueByKey(option, listKey)]"
      >
      </slot>
    </li>
  </ul>
</template>
<script setup lang="ts">
import { inject, ref, toRef, watch, computed } from "vue";
import { useFocusWithin } from "@vueuse/core";
import { getValueByKey } from "../composables/listKeyValue";
import { undefinedIfNotTrue } from "../composables/attributes";
import { useListSelectionState } from "../composables/useListSelectionState";
import { useListFocusState } from "../composables/useListFocusState";
import { useTypeAhead } from "../composables/useTypeAhead";
import { formDisabledKey } from "../types/disabled";

interface Props {
  id: string;
  list: Record<string, unknown>[];
  listKey: string;
  listValue: string;
  multiple?: boolean;
  // inferred type is broken after setting default value to undefined
  // eslint-disable-next-line vue/require-default-prop
  modelValue?: string | string[] | number | number[] | boolean | boolean[];
  useFocusIndication?: boolean;
  noUnselect?: boolean;
  tabindex?: string;
  disabled?: boolean;
  showSelection?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  noUnselect: false,
  multiple: false,
  tabindex: undefined,
  useFocusIndication: false,
  showSelection: true,
});

const formDisabled = inject(formDisabledKey, false);
const isDisabled = computed(() => {
  return formDisabled || props.disabled;
});

const emit = defineEmits<{
  (
    e: "update:modelValue",
    value?: string | string[] | number | number[] | boolean | boolean[],
  ): void;
  (
    e: "focusIndicationChange",
    value?: { id: string; option: Record<string, unknown> },
  ): void;
}>();

const modelValueRef = toRef(props, "modelValue");
const listRef = toRef(props, "list");
const noUnselectRef = toRef(props, "noUnselect");
const listboxContainer = ref<HTMLElement>();
const multipleRef = toRef(props, "multiple");

const { focused } = useFocusWithin(listboxContainer);

const {
  selectionState,
  unselectAll,
  selectAll,
  toggleOption,
  toggleAllOptions,
} = useListSelectionState<"update:modelValue">(
  modelValueRef,
  listRef,
  props.listKey,
  multipleRef,
  isDisabled,
  noUnselectRef,
  { emit, eventName: "update:modelValue" },
);

const {
  focusedIndex,
  optionId,
  setFocusOnOption,
  setOptionRef,
  resetFocus,
  focusByOptionIndex,
  focusFirstOption,
  focusLastOption,
  focusNextOption,
  focusPreviousOption,
} = useListFocusState<"focusIndicationChange">(
  props.id,
  listboxContainer,
  listRef,
  props.listKey,
  props.useFocusIndication,
  { emit, eventName: "focusIndicationChange" },
);

watch(focused, (newValue) => {
  if (!newValue) {
    resetFocus();
  }
});

useTypeAhead(
  listboxContainer,
  listRef,
  props.listKey,
  props.listValue,
  focused,
  focusedIndex,
  focusByOptionIndex,
);

function getTabindex() {
  if (props.tabindex) {
    return props.tabindex;
  }
  return focused.value || props.list.length === 0 ? "-1" : "0";
}

function setOption(option: Record<string, unknown>) {
  if (props.useFocusIndication) {
    toggleOption(option, focusByOptionIndex);
  }
}

defineExpose({
  selectAll,
  unselectAll,
  focusNextOption,
  focusPreviousOption,
  focusFirstOption,
  focusLastOption,
  resetFocus,
  setOption,
});
</script>
