import {
  onBeforeUpdate,
  onMounted,
  ref,
  type Ref,
  type ComputedRef,
} from "vue";
import { findIndexByKey, getValueByKey } from "./listKeyValue";

type ValueT =
  | string
  | string[]
  | number
  | number[]
  | boolean
  | boolean[]
  | undefined;

type EmitT<T> = {
  (e: T, value?: ValueT): void;
};

type EmitSpecificationT<T> = { emit: EmitT<T>; eventName: T };

export const useListSelectionState = <T>(
  sourceValue: Ref<ValueT>,
  list: Ref<Record<string, unknown>[]> | ComputedRef<Record<string, unknown>[]>,
  listKey: string,
  multiple: Ref<boolean> | ComputedRef<boolean>,
  disabled: Ref<boolean | undefined> | ComputedRef<boolean | undefined>,
  noUnselect: Ref<boolean>,
  emitSpecification?: EmitSpecificationT<T>,
) => {
  const selectionState = ref<Record<string | number, boolean>>({});

  function emit(value: ValueT) {
    if (emitSpecification) {
      emitSpecification.emit(emitSpecification.eventName, value);
    }
  }

  onMounted(() => {
    sourceValueToState();
  });

  onBeforeUpdate(() => {
    sourceValueToState();
  });

  function stateToSourceValue() {
    const selectedStateArray = Object.keys(selectionState.value).filter(
      (key) => selectionState.value[key],
    );
    let numberTypeArray = false;
    if (selectedStateArray.length > 0) {
      numberTypeArray = !isNaN(Number(selectedStateArray[0])); //typeof selectedStateArray[0] === "number";
    }
    let modelValue: string | string[] | number | number[] | undefined =
      selectedStateArray;
    if (numberTypeArray) {
      const stateNumberArray = selectedStateArray.map((val) => Number(val));
      if (multiple.value) {
        if (stateNumberArray && stateNumberArray.length > 0) {
          modelValue = stateNumberArray;
        } else {
          modelValue = undefined;
        }
      } else {
        modelValue =
          stateNumberArray.length === 1 ? stateNumberArray[0] : undefined;
      }
    } else {
      if (multiple.value) {
        if (selectedStateArray && selectedStateArray.length > 0) {
          modelValue = selectedStateArray;
        } else {
          modelValue = undefined;
        }
      } else {
        modelValue =
          selectedStateArray.length === 1 ? selectedStateArray[0] : undefined;
      }
    }

    return modelValue;
  }

  function sourceValueToState() {
    selectionState.value = {};
    if (sourceValue.value === undefined) {
      return;
    } else if (
      typeof sourceValue.value === "number" ||
      typeof sourceValue.value === "string"
    ) {
      selectionState.value = { [sourceValue.value]: true };
    } else if (typeof sourceValue.value === "boolean") {
      selectionState.value = { [sourceValue.value.toString()]: true };
    } else {
      sourceValue.value.forEach((val) => {
        if (typeof val === "boolean") {
          selectionState.value[val.toString()] = true;
        } else {
          selectionState.value[val] = true;
        }
      });
    }
  }

  function selectAll() {
    if (multiple.value) {
      list.value.forEach((option) => {
        const key = getValueByKey(option, listKey);
        if (key !== undefined) {
          selectionState.value[key] = true;
        }
      });
    }
    emit(stateToSourceValue());
  }

  function unselectAll() {
    selectionState.value = {};
    emit(stateToSourceValue());
  }

  function setSelection(key: string | number, value: boolean | undefined) {
    const setValue = value === undefined ? false : value;
    if (multiple.value) {
      selectionState.value[key] = setValue;
    } else {
      if (selectionState.value[key]) {
        selectionState.value[key] = setValue;
      } else {
        selectionState.value = {
          [key]: setValue,
        };
      }
    }
  }

  function toggleSelection(key: string | number) {
    setSelection(key, noUnselect.value ? true : !selectionState.value[key]);
  }

  function toggleOption(
    option: Record<string, unknown>,
    focusByOptionIndex?: (index: number) => void,
  ) {
    if (!disabled.value) {
      const key = getValueByKey(option, listKey);
      if (key !== undefined) {
        toggleSelection(key);
        const index = findIndexByKey(list.value, listKey, key);
        if (focusByOptionIndex) {
          focusByOptionIndex(index);
        }
        emit(stateToSourceValue());
      }
    }
  }

  function setAllOptions(value: boolean) {
    list.value.forEach((option) => {
      const key = getValueByKey(option, listKey);
      if (key !== undefined) {
        setSelection(key, value);
      }
    });
    emit(stateToSourceValue());
  }

  function toggleAllOptions() {
    if (!disabled.value) {
      let allSelected = true;
      list.value.some((option) => {
        const key = getValueByKey(option, listKey);
        if (key !== undefined && !selectionState.value[key]) {
          allSelected = false;
          return true;
        }
      });
      setAllOptions(!allSelected);
    }
  }

  return {
    selectionState,
    selectAll,
    unselectAll,
    toggleOption,
    toggleAllOptions,
  };
};
